import React, { useContext, useReducer, useRef, useState } from 'react'
import s from './outStationModal.module.css'
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import c from '../../../assets/css/custom.module.css'
import { indFormat } from '../../../helpers/IndCurrencyFormat';
import axios from 'axios';
import feesData from '../../../fees.json'
import { Context, ContextLogin } from '../../../helpers/context'
import dayjs from 'dayjs';
import { TimePicker, ConfigProvider } from 'antd';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { TextField } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { aws_bucket_url, api_url, json_server_url } from '../../../helpers/base_url';
import moment from 'moment';
import { diffKeys } from 'rc-motion/es/util/diff';
import CryptoJS from 'crypto-js'
import TimeSelector from './DigitalClock';
import TimeSelectorEnd from './DigitalClockend';
function OutStationDateModal({ setIsModalOpen, carName, selectedPackage, rent, useType, thisPackage, details }) {
    // console.log(details.package.standard[0].price * 0.1,"detailssssssssssssss")
    // console.log(details.beta, "detailssssssssssssss")
    // console.log(details.finalprice,"detailssssssssssssss")
    const darkTheme = createTheme({
        components: {
          MuiDateCalendar: {
            styleOverrides: {
              root: {
                backgroundColor: '#333',
                color:'white !important' // Set calendar background globally
              },
            },
          },
          MuiDateCalendar: {
            styleOverrides: {
              weekContainer: {
                backgroundColor: '#333',
                color:'white !important' // Set calendar background globally
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white', // Change the focus outline color to white
                },
              },
              input: {
                color: 'white', // Change the input text color if needed
              },
            },
          },
          MuiPickersDay: {
            styleOverrides: {
              root: {
                // '&.Mui-selected': {
                  backgroundColor: 'transparent',
                  color: 'white !important',
                //   fontweight: '700',
                // },
              },
            },
          },
          MuiPickersDay: {
            styleOverrides: {
              root: {
                '&.Mui-selected': {
                  backgroundColor: 'black !important',
                  color: 'white !important',
                },
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                color:'Black'
              }
            }
        }
        },
      });
    let leadTime = useRef()
    let nav = useNavigate()
    const location = useContext(Context)
    let modal = useRef()
    let startTimeRef = useRef()
    let startDateRef = useRef()
    let endTimeRef = useRef()
    let endDateRef = useRef()
    let showDays = useRef()
    let priceRef = useRef()
    const [showPopup, setShowPopup] = useState(false);
    const [showPopupend, setShowPopupend] = useState(false);
    const loc = useLocation();
    const [fees, setFees] = useState(feesData.fees[0])
    const [clean, setClean] = useState([])
    const [startLead, setStartLead] = useState(false)
    const [endLead, setEndLead] = useState(false)
    const [goaDate, setGoaDate] = useState()
    const [data, setData] = useState({
        date: dayjs().$H > 1 ? dayjs().$d.toLocaleDateString().split('/').join('-') : dayjs().$d.toLocaleDateString().split('/').join('-'),
        time:'',
        // time: dayjs().add(4, 'hour').$d.toTimeString().toString().split(' ')[0].match(/(\d\d:\d\d)/gm)[0],
        startDate: dayjs().$d.toLocaleDateString().split('/').join('-'),
        endDate: dayjs().$d.toLocaleDateString().split('/').join('-'),
        // startTime: dayjs().add(4, 'hour').$d.toTimeString().toString().split(' ')[0].match(/(\d\d:\d\d)/gm)[0],
       startTime:'',
       endTime:'',
        // endTime: dayjs().add(4, 'hour').$d.toTimeString().toString().split(' ')[0].match(/(\d\d:\d\d)/gm)[0],
        extraKm: 0,
        kms: 0,
        deposit: details.package.outstation[0].deposit,
        price: details.finalprice,
        // allowance: details.package.standard[0].price * fees.driverAllowance,
        allowance: details.beta,
        basePrice: details.finalprice,
        stdPrice: details.package.standard[0].price,
        days: '',
        name: details.car.car_brand + '-' + details.car.car_model,
        carId: details.car.id,
        img: details.car.car_image
    })
    const [newDate, setNewDate] = useState(dayjs().$H > 19 ? dayjs() : dayjs())
    const [newEndDate, setNewEndDate] = useState(dayjs().$H > 19 ? dayjs() : dayjs())
    
    const [newTime, setNewTime] = useState(dayjs().add(4, 'hour'))
    const [newEndTime, setNewEndTime] = useState(dayjs().add(4, 'hour'))
    const [timeStamp, setTimeStamp] = useState({
        lead4hrs: ''
    })
    const [daysCount, setDaysCount] = useState()

    useEffect(() => {
        setData({ ...data, startDate: newDate, endDate: newEndDate })
        window.scrollTo(0, 0);
        try {
            modal.current.classList.remove(`${s.slideOutTop}`);
            modal.current.classList.add(`${s.slideInTop}`);
        } catch (error) { }

        axios.get(`${json_server_url}/outStation`)
            .then((res) => { setClean(res.data) })
            .catch((err) => {
                // console.log(err);
            })

        return () => {
            document.body.style.overflowY = 'scroll';
        }
    }, [loc]);




    function getDaysDifference(startDate1, endDate1) {
        // console.log(startDate1,endDate1,'ffffffffffffffffffffffffsssssssss')
        const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
        const start = new Date(startDate1.$d);
        const end = new Date(endDate1.$d);
        const diffInDays = Math.round((end - start) / oneDay);
        // console.log(diffInDays,'dddddddddddddddddddkkkkkkkkkkkkkkkkk')
        return diffInDays;
    }

    useEffect(() => {
        const time=data.startTime
        const [hours, minutes] = time.split(':')
        // setting driver allowance
        
        // if( data.startDate === data.endDate )setDaysCount(1)
        // if(data.endDate>data.startDate) setDaysCount((data.endDate.match(/(\d\d)/gm)[0]-(data.startDate.match(/(\d\d)/gm)[0])) +1)
        if ((parseInt(getDaysDifference(newDate, newEndDate)) + 1) > 0) {
            const oneDay = 24 * 60 * 60 * 1000;
            const start = new Date(newDate.$d);
            const end = new Date(newEndDate.$d);
            // console.log(start ,oneDay,'hhhhhhhhhhhhhhhhhhhhhbbb')
            // setData({ ...data, startDate: start / oneDay, endDate: end / oneDay })
            // console.log(Math.floor(Math.round((end - start) / oneDay)),'rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr')
            const diffInDays = Math.round((end - start) / oneDay);
            const countt = (new Date(data.endDate) - new Date(data.startDate)) / oneDay
            setDaysCount(diffInDays + 1)
            // console.log(daysCount,data.startDate,data.endDate,newDate,'333333333333333333333333')
            if (startDateRef.current.innerHTML === 'Enter Valid Start Date!' || endDateRef.current.innerHTML === 'Enter Valid End Date!') {
                startDateRef.current.style = "visibility:hidden";
                endDateRef.current.style = "visibility:hidden";
            }
        }
        else {
            setDaysCount('0')
            startDateRef.current.innerHTML = 'Enter Valid Start Date!'
            endDateRef.current.innerHTML = 'Enter Valid End Date!'
        }
      
      
        if (dayjs().$D + 1 == newDate.$D && hours > 0 && hours < 7) {
            if (dayjs().$H >= 22 && dayjs().$H < 24 && hours > 0 && hours < 7) {
                startTimeRef.current.innerHTML = 'Early morning booking not possible! select time after 7 AM.'
            }
            setData({ ...data, time: '' })
        }
        else { startTimeRef.current.innerHTML = '' }
        //-----
    if(newDate.$y>newEndDate.$y){

    if (newDate.$M>newEndDate.$M){
            endTimeRef.current.innerHTML ="End Time Cannot be less than Start time"
        
        }
    }

        
        // if (newDate > newEndDate) {
        //     showDays.current.style = "visibility:hidden";
        //     priceRef.current.style = "visibility:hidden";

        // }
        // else {
        //     showDays.current.style = "visibility:visible";
        //     priceRef.current.style = "visibility:visible";
        // }

        //-----
    }, [newDate, newTime, newEndDate, newEndTime, daysCount, data.startDate, data.endDate, data.startTime, data.endTime])

    useEffect(() => {
        setData({ ...data, days: daysCount, price: details.finalprice * daysCount })
    }, [daysCount])
    const allonce_days = (((details.package.standard[0].price * fees.driverAllowance)) * daysCount)
    const exter_km = (data.extraKm * (details.package.standard[0].price * fees.extraKms))
    const overall = ((details.finalprice +
        (details.package.standard[0].price * fees.driverAllowance)) * daysCount) +
        (data.extraKm * (details.package.standard[0].price * fees.extraKms)) - data.allowance
    // console.log('################################',overall)
    // console.log('################################',allonce_days )
    // console.log('################################',exter_km)
    function parameters(){
        startTimeRef.current.innerHTML = '' 
            function dictToURI(dict) {
                var str = [];
                for (var p in dict) {
                    const stringValue = dict[p].toString();


                    const secretKey = process.env.REACT_APP_API_KEY
                    const encryptedData = CryptoJS.AES.encrypt(p, 'secretKey').toString();
                    const encryptedData1 = CryptoJS.AES.encrypt(stringValue, 'secretKey').toString();
                    str.push(encodeURIComponent(encryptedData) + "=" + encodeURIComponent(encryptedData1));
                }

                return str.join("&");
            }
           
            nav(`/luxury-car-rental-in-${localStorage.City}/summary-outstation?${dictToURI(data)}`, { state: data })

    }



    function post_payment() {
        const time=data.startTime
        const [hours, minutes] = time.split(':')
                
       if  (dayjs().$D + 1 == newDate.$D || dayjs().$D == newDate.$D ||newDate.$D==newEndDate.$D && newDate.$M !== newEndDate.$M) {
            if (dayjs().$H + 4 >= 22 && hours < 10 || hours<10 ) {
                startTimeRef.current.innerHTML = 'Early morning booking not possible! select time after 7 AM.'
               
            }
            
                else if (data.startDate == '') startDateRef.current.innerHTML = 'Enter Start Date!'
        
                else if (data.endDate == '') endDateRef.current.innerHTML = 'Enter End Date!'
                else if (data.startTime == '') startTimeRef.current.innerHTML = 'Enter Start Time'
                else if (data.endTime == '') endTimeRef.current.innerHTML = 'Enter End Time'
                else if (data.startTime==data.endTime&&data.startDate==data.endDate)endTimeRef.current.innerHTML = 'Choose different time'
                else if (startLead) startTimeRef.current.innerHTML = 'Lead Time Of 4 Hrs is needed!'
                else if (endLead) startTimeRef.current.innerHTML = 'Lead Time Of 4 Hrs is needed!'
               
                else if (newDate.$M>newEndDate.$M && newDate.$y>newEndDate.$y){
                            endTimeRef.current.innerHTML = "The start date cannot be greater than the end date."
                }   
                else if (newDate.$D==newEndDate.$D && newDate.$M==newEndDate.$M && newDate.$y==newEndDate.$y){
                    if(newTime > newEndTime) endTimeRef.current.innerHTML = "End Time Cannot be less than Start time"
                    else {endTimeRef.current.innerHTML = ""
                        parameters()
                    }
                    
                }     
                else {
endTimeRef.current.innerHTML = ""
                parameters()
            }
          
       }
        
           
            else {
                
                
                parameters()
        }
    

    }

    function postdata() {
        if (newDate.$H > 19) alert('hi')
        else if (data.startDate == '') startDateRef.current.innerHTML = 'Enter Start Date!'

        else if (data.endDate == '') endDateRef.current.innerHTML = 'Enter End Date!'
        else if (data.startTime == '') startTimeRef.current.innerHTML = 'Enter Start Time'
        else if (data.endTime == '') endTimeRef.current.innerHTML = 'Enter End Time'
        else if (startLead) startTimeRef.current.innerHTML = 'Lead Time Of 4 Hrs is needed!'
        else if (endLead) startTimeRef.current.innerHTML = 'Lead Time Of 4 Hrs is needed!'
        else if (newDate.$D!==newEndDate.$){
            if (newDate.$M<newEndDate.$M){
                if(newDate.$y<newEndDate.$y){
                    endTimeRef.current.innerHTML = "End Time Cannot be less than Start time"
                
                }
                else endTimeRef.current.innerHTML = ""
            }
        }
        else {
            for (let i = 0; i < clean.length; i++) {
                try {
                    axios.delete(`${json_server_url}/outStation/${clean[i].id}`)
                        .then((res) => { })
                        .catch((err) => {
                        })
                } catch (error) {
                }
            }

            try {
                axios.post(`${json_server_url}/outStation`, data)
                    .then((res) => { nav(`/luxury-car-rental-in-${localStorage.City}/summary-outstation/${details.car.id}`) })
                    .catch((err) => { console.log(err) })
            } catch (error) { console.log('Not Sent!'); }
        }
    }
    // console.log(newDate);
    // console.log(newEndDate);
    // console.log(newEndDate.$D-newDate.$D+1);
    // console.log(details);
    // console.log(data);

    const disabledHours = () => {
        const currenttime = moment().hours()
        if (newDate <= dayjs()) {

            return [...Array(currenttime + 4).keys()];
        }
        if (currenttime >= 22) {
            const runtime = data.startDate
            let todaydate = runtime.match(/-(\d+)-/)[0].replaceAll("-", "")

            if (todaydate <= ((dayjs().$D) + 1)) {
                return [...Array(10).keys()];
            }
        }
    };
    const handleTimeSelect = (selectedTime) => {
        setNewTime(selectedTime);
        setData({ ...data, startTime: selectedTime });
    };

    const handleTimeSelectend = (selectedTime) => {
        try{
        setNewEndTime(selectedTime);
        setData({ ...data, endTime:selectedTime})
        }catch(e){
            console.log('error',e);
        }
    };
    useEffect(() => {
        const datePickerElement = document.querySelector('#endtime .MuiInputBase-root');
        const datePickerElement1 = document.querySelector('#endtime .MuiFormControl-root');

        if (datePickerElement) {

            datePickerElement.style.position = 'static'; // Remove the position style
        }
        if (datePickerElement1) {

            datePickerElement1.style.position = 'static'; // Remove the position style
        }
    }, []);  

    return (
        <div className={s.mainSection}>
            <div ref={modal} className={s.modalSection}>
                <div className={s.topSection}>
                    <div className={`${c.prefixed} ${s.modalHeading}`}>
                        <u>{useType}</u>
                        <div className={`${s.closeBtn}`}
                            onClick={() => {
                                try {
                                    modal.current.classList.remove(`${s.slideInTop}`)
                                    modal.current.classList.add(`${s.slideOutTop}`)
                                } catch (error) { }
                                setTimeout(() => { setIsModalOpen(false); }, 200)
                            }}>
                            <img src={require('../../../assets/images/icons/close-btn-white.png')} alt="Close-btn" width={48} height={48} />
                        </div>
                    </div>
                    <div className={`${s.modalLandingImage}`}>
                        <img src={`${aws_bucket_url}/${details.car.car_image}`} alt="" />
                    </div>
                </div>
                <div className={s.bottomSection}>
                    <div className={s.carInfoDetails}>
                        <div className={s.nameInfo}>
                            <span className={`${c.bigText} ${c.b500}`}>{carName}</span>
                            <span className={c.smallText}>{selectedPackage || 'Standard - 8 Hr 80Kms'}</span>
                            <span ref={showDays} className={`${c.secondaryColor}`}>{daysCount} {daysCount > 1 ? 'days' : 'day'}</span>
                        </div>
                        <div ref={priceRef} className={`${s.priceInfo} ${c.secondaryColor}`}>
                            {indFormat.format(
                                ((details.finalprice +
                                    // (details.package.standard[0].price * fees.driverAllowance)
                                    (details.beta)) * daysCount) +
                                (data.extraKm * (details.package.standard[0].price * fees.extraKms))
                            )}
                        </div>
                    </div>
                    <div className={s.dateTimeSection}>
                        <div>
                            <span className={c.smallText}>Select Start Date</span>
                            <ThemeProvider theme={darkTheme}>
                                <MobileDatePicker
                                    value={newDate}
                                    format="DD-MM-YYYY"
                                    disablePast
                                    minDate={dayjs().$H > 19 ? dayjs().add(1, 'day') : dayjs()}
                                    renderInput={(params) => <TextField{...params} fullWidth />}
                                    onChange={(newValue) => {
                                        setNewDate(newValue);
                                        setData({ ...data, startDate: dayjs(newValue).$d.toLocaleDateString().split('/').join('-') })
                                        // setData({ ...data, dateonly: dayjs(newValue).$D })
                                        if (newValue.$D == dayjs().$D && newTime < dayjs().add(4, 'hour')) {
                                            let x = 'choose time'
                                            setNewTime(dayjs().add(4, 'hour'))
                                        }setShowPopup(true);
                                    }}
                                />
                            </ThemeProvider>
                            <div ref={startDateRef} className={`${c.tinyText} ${c.secondaryColor}`}></div>
                        </div>
                        <div>
                            <span className={c.smallText}>Select Start Time</span>
                            <ThemeProvider theme={darkTheme}>
                            <TimeSelector showPopup={showPopup} setShowPopup={setShowPopup} onTimeSelect={handleTimeSelect} dat={newDate} />

                            </ThemeProvider>
                            <div ref={startTimeRef} className={`${c.tinyText} ${c.secondaryColor}`}></div>
                        </div>
                        <div>
                            <span className={c.smallText}>Select End Date</span>
                            <div id='endtime'>
                            <ThemeProvider theme={darkTheme}>
                                <MobileDatePicker
                                    defaultValue={newEndDate}
                                    format="DD-MM-YYYY"
                                    disablePast
                                    minDate={dayjs().$H > 19 ? dayjs().add(1, 'day') : dayjs()}
                                    renderInput={(params) => <TextField{...params} fullWidth />}
                                    onChange={(newValue) => {
                                        setNewEndDate(newValue);
                                        setData({ ...data, endDate: dayjs(newValue).$d.toLocaleDateString().split('/').join('-') })
                                        if (newValue.$D == dayjs().$D && newEndTime < dayjs().add(4, 'hour')) {
                                            setNewEndTime(dayjs().add(4, 'hour'))
                                        }setShowPopupend(true);
                                    }}
                                />
                            </ThemeProvider>
                            </div>
                            <div ref={endDateRef} className={`${c.tinyText} ${c.secondaryColor}`}></div>
                        </div>
                        <div>
                            <span className={c.smallText}>Select End Time</span>
                            <ThemeProvider theme={darkTheme}>
                            <TimeSelectorEnd showPopupend={showPopupend} setShowPopupend={setShowPopupend} onTimeSelectend={handleTimeSelectend} datend={newEndDate} />

                            </ThemeProvider>
                            <div ref={endTimeRef} className={`${c.tinyText} ${c.secondaryColor}`}></div>
                        </div>
                        <div>
                            <input type="text" placeholder='Add Additional Kms?' name="additionalKms"
                                // onChange={(e) => { 
                                //     e.target.value === '' ?
                                //     setData({ ...data, extraKm: parseInt(0) }) 
                                //     :
                                //     setData({ ...data, extraKm: parseInt(e.target.value) })  
                                // }} 
                                onChange={(e) => {
                                    let inputValue = parseInt(e.target.value);
                                    if (inputValue < 0) {
                                        inputValue = 0; // Reset negative values to 0
                                    }
                                    if (isNaN(inputValue)) {
                                        inputValue = 0; // Reset non-numeric values to 0
                                    }
                                    let limitedValue = Math.min(inputValue, 99);
                                    setData({ ...data, extraKm: limitedValue });
                                }}
                            />
                        </div>
                    </div>
                    <div className={s.btnSection}>
                        <div className={c.extraSmallText}>* Minimum lead time is 4hrs.</div>
                        <div className={c.extraSmallText}>* Additional Driver allowance applicable for rides between <span className={c.secondaryColor}>9 pm - 7 am</span>.</div>
                        <div className={c.extraSmallText}>* Distance and Time is calculated from garage to garage.</div>                        <div>
                            <button className={`${c.continueBtnFilled}`} onClick={() => {

                                post_payment();

                            }}>Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OutStationDateModal