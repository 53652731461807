import React, { useContext, useReducer, useRef, useState } from 'react'
import s from './outStationModal.module.css'
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import c from '../../../assets/css/custom.module.css'
import { indFormat } from '../../../helpers/IndCurrencyFormat';
import axios from 'axios';
import feesData from '../../../fees.json'
import { Context, ContextLogin } from '../../../helpers/context'
import { aws_bucket_url, api_url, json_server_url } from '../../../helpers/base_url';
import dayjs from 'dayjs';
import { TimePicker, ConfigProvider } from 'antd';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { TextField } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import moment from 'moment';
import CryptoJS from 'crypto-js'
import TimeSelector from './DigitalClock';
import TimeSelectorEnd from './DigitalClockend';

function SelfDriveModal({ setIsModalOpen, carName, selectedPackage, rent, useType, thisPackage, details }) {
    const darkTheme = createTheme({
        components: {
          MuiDateCalendar: {
            styleOverrides: {
              root: {
                backgroundColor: '#333',
                color:'white !important' // Set calendar background globally
              },
            },
          },
          MuiDateCalendar: {
            styleOverrides: {
              weekContainer: {
                backgroundColor: '#333',
                color:'white !important' // Set calendar background globally
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white', // Change the focus outline color to white
                },
              },
              input: {
                color: 'white', // Change the input text color if needed
              },
            },
          },
          MuiPickersDay: {
            styleOverrides: {
              root: {
                // '&.Mui-selected': {
                  backgroundColor: 'transparent',
                  color: 'white !important',
                //   fontweight: '700',
                // },
              },
            },
          },
          MuiPickersDay: {
            styleOverrides: {
              root: {
                '&.Mui-selected': {
                  backgroundColor: 'black !important',
                  color: 'white !important',
                },
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                color:'Black'
              }
            }
        }
        },
      });

    let nav = useNavigate()
    let modal = useRef()
    let startTimeRef = useRef()
    let startDateRef = useRef()
    let endTimeRef = useRef()
    let endDateRef = useRef()
    let showDays = useRef()
    let priceRef = useRef()
    const loc = useLocation();
    const location = useContext(Context)
    const [showPopup, setShowPopup] = useState(false);
    const [showPopupend, setShowPopupend] = useState(false);


    const [fees, setFees] = useState(feesData.fees[0])
    const [clean, setClean] = useState([])
    const [startLead, setStartLead] = useState(false)
    const [endLead, setEndLead] = useState(false)
    const [goaDate, setGoaDate] = useState()
    const [data, setData] = useState({
        startDate: dayjs().add(2, "day").$d.toLocaleDateString().split('/').join('-'),
        date: dayjs().$H > 1 ? dayjs().$d.toLocaleDateString().split('/').join('-') : dayjs().$d.toLocaleDateString().split('/').join('-'),
        // time: dayjs().add(4, 'hour').$d.toTimeString().toString().split(' ')[0].match(/(\d\d:\d\d)/gm)[0],
        time: '',
        endDate: dayjs().add(2, "day").$d.toLocaleDateString().split('/').join('-'),
        // startTime: dayjs().$d.toTimeString().toString().split(' ')[0].match(/(\d\d:\d\d)/gm)[0],
        startTime: '',
        endTime: '',
        // endTime: dayjs().$d.toTimeString().toString().split(' ')[0].match(/(\d\d:\d\d)/gm)[0],
        extraKm: 0,
        deposit: details.price,
        price: details.price,
        basePrice: details.deposit,
        days: '',
        name: details.carName,
        carId: details.id,
        img: details.carImage
    })
    const [newDate, setNewDate] = useState(dayjs().add(2, 'day'))
    const [newEndDate, setNewEndDate] = useState(dayjs().add(2, 'day'))
    const [newTime, setNewTime] = useState(dayjs())
    const [newEndTime, setNewEndTime] = useState(dayjs())
    const [timeStamp, setTimeStamp] = useState({
        lead4hrs: ''
    })
    const [daysCount, setDaysCount] = useState()
    // console.log(details,'asd@@@@@@@@@@@@')

    useEffect(() => {
        window.scrollTo(0, 0);
        try {
            modal.current.classList.remove(`${s.slideOutTop}`);
            modal.current.classList.add(`${s.slideInTop}`);
        } catch (error) { }

        axios.get(`${json_server_url}/selfDrive`)
            .then((res) => { setClean(res.data) })
            .catch((err) => { console.log(err); })

        return () => {
            document.body.style.overflowY = 'scroll';
        }
    }, [loc]);
    function post_payment() {
        function dictToURI(dict) {
            var str = [];
            for (var p in dict) {
                const stringValue = dict[p].toString();


                const secretKey = process.env.REACT_APP_API_KEY
                const encryptedData = CryptoJS.AES.encrypt(p, 'secretKey').toString();
                const encryptedData1 = CryptoJS.AES.encrypt(stringValue, 'secretKey').toString();
                str.push(encodeURIComponent(encryptedData) + "=" + encodeURIComponent(encryptedData1));
            }

            return str.join("&");
        }
        startDateRef.current.innerHTML = '';
endDateRef.current.innerHTML = '';
startTimeRef.current.innerHTML = '';
endTimeRef.current.innerHTML = '';
        if (data.startDate > data.endDate) {
            endDateRef.current.innerHTML = 'Entered End date Cannot be less than Start date'
            // alert('Entered End date Cannot be less than Start date')
        } else if (data.startDate == '') startDateRef.current.innerHTML = 'Enter Start Date!'

        else if (data.endDate == '') endDateRef.current.innerHTML = 'Enter End Date!'
        else if (data.startTime == '') startTimeRef.current.innerHTML = 'Enter Start Time'
        else if (data.endTime == '') endTimeRef.current.innerHTML = 'Enter End Time'
        else if (data.startTime == data.endTime && data.startDate == data.endDate) endTimeRef.current.innerHTML = 'Choose different time'
        else {
            data.price = data.price +
                data.driverAllowance +
                data.parking +
                data.toll
            // console.log('darun',data)
            nav(`/self-driving-cars-in-${localStorage.City}/summary-self-drive?${dictToURI(data)}`, { state: data })
            //  nav('/payments',{state:data})
          
            // axios.post(`${api_url}/create_order`, data)
            // .then(() => { nav(`/summary/${details.car.id}`) })
            // .catch((err) => { console.log(err); })


        }
    }
    useEffect(() => {
        //setting days count
        const oneDay = 24 * 60 * 60 * 1000;
        const start = new Date(newDate.$d);
        const end = new Date(newEndDate.$d);
        const countt = Math.round((end - start) / oneDay);
        // console.log(countt,'ggggggggggggggggggg')
        setDaysCount(countt + 1)
        setData({ ...data, days: daysCount })

        //-----
        // //setting driver allowance`
        // if (newTime.$H >= 13 && newTime.$H <= 24 || newTime.$H >= 0 && newTime.$H < 8) {
        //     setData({ ...data, allowance: details.price * fees.driverAllowance });
        // }
        // else { setData({ ...data, allowance: 0 }); }
        // //-----`
        // //handling early morning bookings
        // if (dayjs().$D + 1 == newDate.$D && newTime.$H > 0 && newTime.$H < 8) {
        //     if (dayjs().$H >= 22 && dayjs().$H < 24) {
        //         alert('Early morning booking not possible! select time after 7 AM.');
        //         setData({ ...data, time: '' })
        //     }
        // }
        // //-----
        if (newDate >= newEndDate && newTime > newEndTime) endTimeRef.current.innerHTML = "End Time Cannot be less than Start time"
        else endTimeRef.current.innerHTML = ""

        // if (newDate > newEndDate) {
        //     showDays.current.style = "visibility:hidden";
        //     priceRef.current.style = "visibility:hidden";
        // }
        // else {
        //     showDays.current.style = "visibility:visible";
        //     priceRef.current.style = "visibility:visible";
        // }
        //-----
    }, [newDate, newTime, newEndDate, newEndTime, daysCount, data.startDate, data.endDate, data.startTime, data.endTime])

    function postdata() {
        // console.log(clean,'@@@@@@@@@@@@@@@@@@@@@sdfsdg')
        if (22 > 26) endDateRef.current.innerHTML = "End Time Cannot be less than Start time"
        startDateRef.current.innerHTML = '';
        endDateRef.current.innerHTML = '';
        startTimeRef.current.innerHTML = '';
        endTimeRef.current.innerHTML = '';
        if (data.startDate == '') startDateRef.current.innerHTML = 'Enter Start Date!'

        else if (data.endDate == '') endDateRef.current.innerHTML = 'Enter End Date!'
        else if (data.startTime == '') startTimeRef.current.innerHTML = 'Enter Start Time'
        else if (startLead) startTimeRef.current.innerHTML = 'Lead Time Of 4 Hrs is needed!'
        else if (data.endTime == '') endTimeRef.current.innerHTML = 'Enter End Time'
        else if (endLead) endTimeRef.current.innerHTML = 'Lead Time Of 4 Hrs is needed!'
        else if (newTime > newEndTime) endTimeRef.current.innerHTML = "End Time Cannot be less than Start time"
        else if (22 > 26) endDateRef.current.innerHTML = "End Time Cannot be less than Start time"
        else {
            try {
                axios.post(`${api_url}/selfDrive`, data)
                    .then((res) => { nav(`/self-driving-cars-in-${localStorage.City}/summary-self-drive/${details.id}`) })
                    .catch((err) => { console.log(err) })
            } catch (error) { console.log('Not Sent!'); }
            for (let i = 0; i < clean.length; i++) {
                try {

                    axios.delete(`${json_server_url}/selfDrive/${clean[i].id}`)
                        .then((res) => { })
                        .catch((err) => { console.log(err) })
                } catch (error) { console.log('Not deleted!'); }
            }
            try {
                axios.post(`${api_url}/selfDrive`, data)
                    .then((res) => { nav(`/self-driving-cars-in-${localStorage.City}/summary-self-drive/${details.id}`) })
                    .catch((err) => { console.log(err) })
            } catch (error) { console.log('Not Sent!'); }
        }
    }




    const handleTimeSelect = (selectedTime) => {
        setNewTime(selectedTime);
        setData({ ...data, startTime: selectedTime });
    };

    const handleTimeSelectend = (selectedTime) => {
        try {
            setNewEndTime(selectedTime);
            setData({ ...data, endTime: selectedTime })
        } catch (e) {
            console.log('error', e);
        }
    };
    useEffect(() => {
        const datePickerElement = document.querySelector('#endtime .MuiInputBase-root');
        const datePickerElement1 = document.querySelector('#endtime .MuiFormControl-root');

        if (datePickerElement) {

            datePickerElement.style.position = 'static'; // Remove the position style
        }
        if (datePickerElement1) {

            datePickerElement1.style.position = 'static'; // Remove the position style
        }
    }, []);

    return (
        <div className={s.mainSection}>
            <div ref={modal} className={s.modalSection}>
                <div className={s.topSection}>
                    <div className={`${c.prefixed} ${s.modalHeading}`}>
                        <u>{useType}</u>
                        <div className={`${s.closeBtn}`}
                            onClick={() => {
                                try {
                                    modal.current.classList.remove(`${s.slideInTop}`)
                                    modal.current.classList.add(`${s.slideOutTop}`)
                                } catch (error) { }
                                setTimeout(() => { setIsModalOpen(false); }, 200)
                            }}>
                            <img src={require('../../../assets/images/icons/close-btn-white.png')} alt="Close-btn" width={48} height={48} />
                        </div>
                    </div>
                    <div className={`${s.modalLandingImage}`}>
                        <img src={`${aws_bucket_url}/${details.carImage}`} alt="car-image" />
                    </div>
                </div>
                <div className={s.bottomSection}>
                    <div className={s.carInfoDetails}>
                        <div className={s.nameInfo}>
                            <span className={`${c.bigText} ${c.b500}`}>{details.carName}</span>
                            <span className={c.smallText}>{"Self Drive"}</span>
                            <span ref={showDays} className={`${c.secondaryColor}`}>{daysCount} {daysCount > 1 ? 'days' : 'day'}</span>
                        </div>
                        <div ref={priceRef} className={`${s.priceInfo} ${c.secondaryColor}`}>
                            {indFormat.format(
                                ((data.price) * (daysCount) +

                                    (data.extraKm * (data.price * fees.extraKms))

                                )

                            )}
                        </div>
                    </div>
                    <div className={s.dateTimeSection}>
                        <div>
                            <span className={c.smallText}>Select Start Date</span>
                            <ThemeProvider theme={darkTheme}>
                                <MobileDatePicker
                                    defaultValue={newDate}
                                    format="DD-MM-YYYY"
                                    disablePast
                                    minDate={dayjs().add(2, 'day')}
                                    renderInput={(params) => <TextField{...params} fullWidth />}
                                    onChange={(newValue) => {
                                        setNewDate(newValue);
                                        // setData({ ...data, dateonly: dayjs(newValue).$D })
                                        setData({ ...data, startDate: dayjs(newValue).$d.toLocaleDateString().split('/').join('-') })
                                        if (newValue.$D == dayjs().$D && newTime < dayjs().add(4, 'hour')) {
                                            setNewTime(dayjs().add(4, 'hour'))
                                        }
                                        setShowPopup(true)
                                    }}
                                />
                            </ThemeProvider>
                            <div ref={startDateRef} className={`${c.tinyText} ${c.secondaryColor}`}></div>
                        </div>
                        <div>
                            <span className={c.smallText}>Select Start Time</span>
                            <ThemeProvider theme={darkTheme}>
                                <ConfigProvider>

                                    <TimeSelector showPopup={showPopup} setShowPopup={setShowPopup} onTimeSelect={handleTimeSelect} dat={newDate} />


                                </ConfigProvider>
                            </ThemeProvider>
                            <div ref={startTimeRef} className={`${c.tinyText} ${c.secondaryColor}`}></div>
                        </div>
                        <div>
                            <span className={c.smallText}>Select End Date</span>
                            <div id='endtime'>
                                <ThemeProvider theme={darkTheme}>
                                    <MobileDatePicker
                                        value={newEndDate}
                                        format="DD-MM-YYYY"
                                        disablePast
                                        minDate={dayjs().add(2, 'day')}
                                        renderInput={(params) => <TextField{...params} fullWidth />}
                                        onChange={(newValue) => {
                                            setNewEndDate(newValue);
                                            setData({ ...data, endDate: dayjs(newValue).$d.toLocaleDateString().split('/').join('-') })
                                            if (newValue.$D == dayjs().$D && newEndTime < dayjs().add(4, 'hour')) {
                                                setNewEndTime(dayjs().add(4, 'hour'))
                                            } setShowPopupend(true);
                                        }}
                                    />
                                </ThemeProvider>
                            </div>
                            <div ref={endDateRef} className={`${c.tinyText} ${c.secondaryColor}`}></div>
                        </div>
                        <div>
                            <span className={c.smallText}>Select End Time</span>
                            <ThemeProvider theme={darkTheme}>
                                <ConfigProvider>
                                    <TimeSelectorEnd showPopupend={showPopupend} setShowPopupend={setShowPopupend} onTimeSelectend={handleTimeSelectend} datend={newEndDate} />
                                </ConfigProvider>
                            </ThemeProvider>
                            <div ref={endTimeRef} className={`${c.tinyText} ${c.secondaryColor}`}></div>
                        </div>
                        <div>
                            <input type="text" placeholder='Add Additional Kms?' name="additionalKms"
                                // onChange={(e) => { 
                                //     e.target.value === ''?
                                //     setData({ ...data, extraKm: parseInt(0) }) 
                                //     :
                                //     setData({ ...data, extraKm: parseInt(e.target.value) }) 
                                // }}
                                onChange={(e) => {
                                    let inputValue = parseInt(e.target.value);
                                    if (inputValue < 0) {
                                        inputValue = 0; // Reset negative values to 0
                                    }
                                    if (isNaN(inputValue)) {
                                        inputValue = 0; // Reset non-numeric values to 0
                                    }
                                    let limitedValue = Math.min(inputValue, 99);
                                    setData({ ...data, extraKm: limitedValue });
                                }}
                            />
                        </div>
                    </div>
                    <div className={s.btnSection}>
                        {location == 3 && <div className={c.extraSmallText}>* 1 day = 9:00am to 9:00am.</div>}
                        {localStorage.City == "Goa" && <div className={c.extraSmallText}>* In Goa minimum rental is for 2 days.</div>}
                        <div className={c.extraSmallText}>* Pickup & Drop charges applicable.</div>
                        <div className={c.extraSmallText}>* Distance and Time is calculated from garage to garage.</div>
                        <div>
                            <button className={`${c.continueBtnFilled}`} onClick={() => {
                                // if (data.date === '') alert('Enter date')
                                // else if (data.time === '') alert('Enter time')
                                // else {
                                //     localStorage.setItem('price', JSON.stringify(data));
                                //     postdata()
                                // }
                                post_payment()
                            }
                            }>Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelfDriveModal