import React, { createContext, useReducer, useRef, useState } from 'react'
import s from './selectDate.module.css'
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import c from '../../../assets/css/custom.module.css'
import { indFormat } from '../../../helpers/IndCurrencyFormat';
import axios from 'axios';
import feesData from '../../../fees.json'
import dayjs from 'dayjs';
import { TimePicker, ConfigProvider } from 'antd';
// import {TimePicker} from 'react-time-picker';
// import { Button, ConfigProvider, theme } from 'antd';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { TextField } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import { ThemeProvider } from 'styled-components';
import { api_url, aws_bucket_url, json_server_url } from '../../../helpers/base_url';
import { red } from '@mui/material/colors';
import moment from 'moment';
import CryptoJS from 'crypto-js'
import TimeSelector from './DigitalClock';
// import { DataProvider } from './Appcontext';


function SelectDate({ setIsModalOpen, carName, selectedPackage, useType, details, children }) {
    // console.log(details.finalprice,"detailssssssssssssss")
    // const darkTheme = createTheme({
    //     palette: {
    //         mode: 'dark',
    //     },
    // });
    const darkTheme = createTheme({
        components: {
          MuiDateCalendar: {
            styleOverrides: {
              root: {
                backgroundColor: '#333',
                color:'white !important' // Set calendar background globally
              },
            },
          },
          MuiDateCalendar: {
            styleOverrides: {
              weekContainer: {
                backgroundColor: '#333',
                color:'white !important' // Set calendar background globally
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white', // Change the focus outline color to white
                },
              },
              input: {
                color: 'white', // Change the input text color if needed
              },
            },
          },
          MuiPickersDay: {
            styleOverrides: {
              root: {
                // '&.Mui-selected': {
                  backgroundColor: 'transparent',
                  color: 'white !important',
                //   fontweight: '700',
                // },
              },
            },
          },
          MuiPickersDay: {
            styleOverrides: {
              root: {
                '&.Mui-selected': {
                  backgroundColor: 'black !important',
                  color: 'white !important',
                },
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                color:'Black'
              }
            }
        }
        },
      });

    let modal = useRef()
    let leadTime = useRef()
    let nav = useNavigate()
    const location = useLocation();
    const [showPopup, setShowPopup] = useState(false);
    let dateref = useRef()
    const timePickerRef = useRef(null);

    const [fees, setFees] = useState(feesData.fees[0])
    const [clean, setClean] = useState([])
    const [refDate, setRefDate] = useState(dayjs())
    const [newDate, setNewDate] = useState(dayjs().$H > 1 ? dayjs() : dayjs())
    const [newTime, setNewTime] = useState()
    const [early, setEarly] = useState(false)
    const [lead, setLead] = useState(false)
    const [data, setData] = useState({
        name: carName,
        date: dayjs().$H > 1 ? dayjs().$d.toLocaleDateString().split('/').join('-') : dayjs().$d.toLocaleDateString().split('/').join('-'),
        // time: dayjs().$d.toTimeString().toString().split(' ')[0].match(/(\d\d:\d\d)/gm)[0],
        time:'',
        startDate: dayjs().$d.toLocaleDateString().split('/').join('-'),
        startTime:'',
        // startTime: dayjs().add(4, 'hour').$d.toTimeString().toString().split(' ')[0],
        allowance: '',
        additionalKms: 0,
        additionalHrs: 0,
        price: details.finalprice,
        deposit: details.package.deposit,
        img: details.car.car_image
    })
    const [timeStamp, setTimeStamp] = useState({
        lead4hrs: ''
    })

    useEffect(() => {
        window.scrollTo(0, 0);
        try {
            modal.current.classList.remove(`${s.slideOutTop}`);
            modal.current.classList.add(`${s.slideInTop}`);
        } catch (error) { }

        // document.body.style.overflow = 'hidden';

        axios.get(`${json_server_url}/summary`)
            .then((res) => {
                setClean(res.data)
            })
        return () => {
            document.body.style.overflowY = 'scroll';
        }
    }, [location]);

    function post_payment() {

        function dictToURI(dict) {


            var str = [];
            for (var p in dict) {
                const stringValue = dict[p].toString();


                const secretKey = process.env.REACT_APP_API_KEY
                const encryptedData = CryptoJS.AES.encrypt(p, 'secretKey').toString();
                const encryptedData1 = CryptoJS.AES.encrypt(stringValue, 'secretKey').toString();
                str.push(encodeURIComponent(encryptedData) + "=" + encodeURIComponent(encryptedData1));
            }

            return str.join("&");
        }
        if (data.startTime === '' || data.date === '') {
            console.log(data)
            alert('Enter Time!')
        } else {
            data.price =
                parseInt(details.finalprice)
            // parseInt(details.finalprice * fees.driverAllowance) +
            // (parseInt(data.additionalKms) * parseInt(details.finalprice * fees.extraKms)) +
            // (parseInt(data.additionalHrs) * parseInt(details.finalprice * fees.extraHrs))
            data.kms = parseInt(data.additionalKms)
            data.additionalKms_price =
                (parseInt(data.additionalKms) * parseInt(details.finalprice * fees.extraKms)) +
                (parseInt(data.additionalHrs) * parseInt(details.finalprice * fees.extraHrs))
            // nav('/payments',{state:data})
            // nav(`/summary-outstation/${details.id}`)
            // nav(`/summary/${details.id}`)
            console.log(data, 'ffffffffff')
            nav(`/luxury-car-rental-in-${localStorage.City}/summary?${dictToURI(data)}`, { state: data })


            if (data.date === '') alert('Enter date!s')
            else if (data.startTime === '') alert('Enter time!')
            else {
                localStorage.setItem('price', JSON.stringify(data));
                postdata()
            }
            // axios.post(`${api_url}/create_order`, data)
            // .then(() => { nav(`/summary/${details.car.id}`) })
            // .catch((err) => { console.log(err); })
        }
    }
    useEffect(() => {
        // adding lead time of 4 hrs to timestamp
        setTimeStamp({ ...timeStamp, lead4hrs: refDate.add(4, 'hour') });

        // setting driver allowance
        if (newTime) {
            const [hours, minutes] = newTime.split(':')
            const newAllowance = hours >= 13 && hours <= 24 || hours >= 0 && hours < 8 ?
                details.finalprice * fees.driverAllowance :
                0;
            setData(prevData => ({ ...prevData, allowance: newAllowance }));



            // handling early morning bookings
            if ((dayjs().$D + 1 === newDate.$D || dayjs().$D === newDate.$D) && dayjs().$H + 4 >= 22 && hours < 10) {
                leadTime.current.innerHTML = 'Early morning booking not possible! select time after 7 AM.';
                setData(prevData => ({ ...prevData, time: '' }));
            } else {
                leadTime.current.innerHTML = '';
            }
        }
    }, [newDate, newTime]);
    useEffect(() => {
        // adding lead time of 4 hrs to timestamp
        setTimeStamp({ ...timeStamp, lead4hrs: refDate.add(4, 'hour') });

        // setting driver allowance
        if (newTime) {
            const [hours, minutes] = newTime.split(':')
            const newAllowance = hours >= 13 && hours <= 24 || hours >= 0 && hours < 8 ?
                details.finalprice * fees.driverAllowance :
                0;
            setData(prevData => ({ ...prevData, allowance: newAllowance }));

            // handling early morning bookings
            if ((dayjs().$D + 1 === newDate.$D || dayjs().$D === newDate.$D) && dayjs().$H + 4 >= 22 && hours < 10) {
                leadTime.current.innerHTML = 'Early morning booking not possible! select time after 7 AM.';
                setData(prevData => ({ ...prevData, time: '' }));
            } else {
                leadTime.current.innerHTML = '';
            }
        }
    }, []);
    // useEffect(()=>{
    //     const button = document.querySelector('MuiPickersSlideTransition-root MuiDayCalendar-slideTransition css-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition ,button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected');
    //     console.log(button,'enter')
    // if (button) {
    //     console.log('white')
    //     button.style.backgroundColor = 'white';
    // }
    // },[newDate])

    function postdata() {

        if (dayjs().$H + 4 >= 22) {
            leadTime.current.innerHTML = 'Early morning booking not possible! select time after 7 AM.'
            setData({ ...data, time: '' })
        }
        else { leadTime.current.innerHTML = '' }

        for (let i = 0; i < clean.length; i++) {
            try {
                axios.delete(`${json_server_url}/summary/${clean[i].id}`)
            } catch (error) {
                // console.log('not deleted');
            }
        }
        if (data.date == '') alert('Enter Date!q')
        else if (newDate.$D < dayjs().$D) alert("Selected Date Cannot be less than today's")
        else if (data.startTime == '') alert('Enter Time!')
        else if (lead) alert("Give 4 Hrs Lead Time")
        else if (early) alert("Early morning booking is not possible after 10 PM.")
        else {
            // axios.post(`${json_server_url}/summary`, data)
            //     .then(() => { nav(`/summary/${details.car.id}`) })
            //     .catch((err) => { console.log(err); })
        }
    }
    const disabledHours = () => {
        const currenttime = moment().hours()
        if (newDate <= dayjs()) {

            return [...Array(currenttime + 4).keys()];
        }
        if (currenttime >= 22) {
            const runtime = data.date
            let todaydate = runtime.match(/-(\d+)-/)[0].replaceAll("-", "")

            if (todaydate <= ((dayjs().$D) + 1)) {
                return [...Array(10).keys()];
            }
        }
    };

    const handleDateChange = (newValue) => {
        setNewDate(newValue);
        setData({ ...data, startDate: dayjs(newValue).format('DD-MM-YYYY') });
        setShowPopup(true);
    };

    const handleTimeSelect = (selectedTime) => {
        setNewTime(selectedTime);
        setData({ ...data, startTime: selectedTime });
    };



    return (
        <div className={s.mainSection}>
            <div ref={modal} className={s.modalSection}>
                <div className={s.topSection}>
                    <div className={`${c.prefixed} ${s.modalHeading}`}>
                        <u>{useType}</u>
                        <div className={`${s.closeBtn}`}
                            onClick={() => {
                                try {
                                    modal.current.classList.remove(`${s.slideInTop}`)
                                    modal.current.classList.add(`${s.slideOutTop}`)
                                } catch (error) { }
                                setTimeout(() => {
                                    setIsModalOpen(false);
                                }, 200)
                            }}>
                            <img src={require('../../../assets/images/icons/close-btn-white.png')} alt="Close-btn" width={48} height={48} />
                        </div>
                    </div>
                    <div className={`${s.modalLandingImage}`}>
                        <img src={`${aws_bucket_url}/${details.car.car_image}`} alt="" />
                    </div>
                </div>
                <div className={s.bottomSection}>
                    <div className={s.carInfoDetails}>
                        <div className={s.nameInfo}>
                            <span className={`${c.bigText} ${c.b500}`}>{carName}</span>
                            <span className={c.smallText}>{selectedPackage || 'Standard - 8 Hr 80Kms'}</span>
                        </div>
                        <div className={`${s.priceInfo} ${c.secondaryColor}`}>{
                            indFormat.format(
                                parseInt(details.finalprice) +
                                // parseInt(details.finalprice * fees.driverAllowance) +
                                (parseInt(data.additionalKms) * parseInt(details.finalprice * fees.extraKms)) +
                                (parseInt(data.additionalHrs) * parseInt(details.finalprice * fees.extraHrs))
                            )
                        }</div>
                    </div>
                    <div className={s.dateTimeSection}>
                        <div>

                            <span className={c.smallText}>Select Date</span>
                            <ThemeProvider theme={darkTheme}>
                            <MobileDatePicker
                            sx={{
                                '.MuiDateCalendar-root': {
                                  backgroundColor: 'white', // Change the background color
                                  borderRadius: '111px',
                                },
                                '.MuiPickersDay-root': {
                                  color: 'white', // Change day text color
                                  '&.Mui-selected': {
                                    backgroundColor: 'white', // Change selected day background color
                                  },
                                },
                                '.MuiPickersCalendarHeader-label': {
                                  fontSize: '1.2rem', // Adjust the font size of the header
                                  color: 'white',
                                },
                                '.MuiIconButton-root': {
                                  color: 'white', // Change arrow button color
                                },
                              }}
                            value={newDate}
                            format="DD-MM-YYYY"
                            disablePast
                            renderInput={(params) => <TextField {...params} fullWidth />}
                            onChange={handleDateChange}
                        />
                            </ThemeProvider>
                        </div>
                        <div className={c.sm}>
                            <span className={c.smallText}>Select Time</span>
                            <ThemeProvider theme={darkTheme}>
                            <ConfigProvider>
                                
                                <TimeSelector showPopup={showPopup} setShowPopup={setShowPopup} onTimeSelect={handleTimeSelect} dat={newDate} />
                                

                            </ConfigProvider>
                            </ThemeProvider>

                            <div ref={leadTime} className={`${c.tinyText} ${c.secondaryColor}`}></div>
                        </div>
                        <div>
                            <input type="text" placeholder='Add Additional Kms' name="additionalKms"

                                onChange={(e) => {
                                    let inputValue = parseInt(e.target.value);
                                    if (inputValue < 0) {
                                        inputValue = 0; // Reset negative values to 0
                                    }
                                    if (isNaN(inputValue)) {
                                        inputValue = 0; // Reset non-numeric values to 0
                                    }
                                    let limitedValue = Math.min(inputValue, 99);
                                    setData({ ...data, additionalKms: limitedValue });
                                }}
                            />

                            <span className={c.tinyText}>Extra Km {indFormat.format(details.finalprice * fees.extraKms)}</span>
                        </div>
                        <div>
                            <input type="text" placeholder='Add Additional hrs' name="additionalHrs"
                                onChange={(e) => {
                                    let inputValue = parseInt(e.target.value);
                                    if (inputValue < 0) {
                                        inputValue = 0; // Reset negative values to 0
                                    }
                                    if (isNaN(inputValue)) {
                                        inputValue = 0; // Reset non-numeric values to 0
                                    }
                                    setData({ ...data, additionalHrs: inputValue });
                                }} />
                            <span className={c.tinyText}>Extra Hr {indFormat.format(details.finalprice * fees.extraHrs)}</span>
                        </div>
                    </div>
                    <div className={s.btnSection}>
                        <div className={c.extraSmallText}>* Minimum lead time is 4hrs.</div>
                        <div className={c.extraSmallText}>* Driver Batta applicable for rides between <span className={c.secondaryColor}>9 pm – 7 am</span>.</div>
                        {/* <div className={c.extraSmallText}>* Driver Batta applicable for rides between <span className={c.secondaryColor}>7 - 21 hrs</span>.</div> */}

                        <div className={c.extraSmallText}>* Distance and Time is calculated from garage to garage.</div>
                        <div>
                            <button className={`${c.continueBtnFilled}`} onClick={post_payment}>Continue</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectDate