import CreateAccount from "../pages/auth/CreateAccount";
import LoginPage from '../pages/auth/LoginPageOtp'
import Adminpage from '../pages/auth/admin_login'
import ContactUs from "../pages/contactus/ContactUs";
import Details from "../pages/details/Details";
import DetailsSelfDrive from "../pages/details/DetailsSelfDrive";
import HomePage from "../pages/homepage/HomePage";
import Profile from "../pages/profile/Profile";
import Summary from "../pages/summary/Summary";
import SummaryAirport from "../pages/summary/SummaryAirport";
import SummaryOutstation from "../pages/summary/SummaryOutstation";
import SummarySelfDrive from "../pages/summary/SummarySelfDrive";
import Jet from "../pages/JetsCopter/Jet";
import Yachts from "../pages/Yachts/Yachts";
import TermsAndConditions from "../pages/legal/TermsAndConditions";
import PrivacyPolicy from "../pages/legal/PrivacyPolicy";
import RefundAndCancellation from "../pages/legal/RefundAndCancellation";
import Feedback from "../pages/company_info/feedback/Feedback";
import Payments from "../pages/payment/payments";
import Partnerships from "../pages/company_info/partnerships/Partnerships";
import Partnerwithus from "../pages/company_info/partnerWithUs/Partnerwithus";
import Aboutus from "../pages/company_info/about_Us/About";
import Car_blog from "../pages/company_info/blog/accelerate";
import Car_blogg from "../pages/company_info/blog/car_rental";
import Car_blog3 from "../pages/company_info/blog/take_your";
import Car_blog4 from "../pages/company_info/blog/rev_up";
import Car_blog5 from "../pages/company_info/blog/beyond_the";
import Car_blog6 from "../pages/company_info/blog/gear_up";
import Car_blog7 from "../pages/company_info/blog/cruise_into";
import Car_blog8 from "../pages/company_info/blog/experience";
import Car_blog9 from "../pages/company_info/blog/class_and";
import Car_blog10 from "../pages/company_info/blog/1celebrate";

import Main_blog from "../pages/company_info/blog/Main";
import Thankyou from "../pages/summary/ThankYou"




// import Navbar from "../pages/nav-foot/Navbar";
// import LoginPage from "../pages/auth/LoginPage";

export const AllRoutes = [
    { id:0 ,path: "*", element: <HomePage/> },
    { id:1, path: "/login" , element: <LoginPage/> },
    // { id:1, path: "/" , element: <LoginPage/> },
    { id:2, path: "/signup" , element: <CreateAccount/> },
    { id:3, path: "/" , element: <HomePage/> },
    { id:4, path: "/:location/:package/:id" , element: <Details/> },
    { id:5, path: "/:location/details-self-drive/:id" , element: <DetailsSelfDrive/> },
    { id:6, path: "/:location/summary" , element: <Summary/> },
    { id:7, path: "/:location/summary-self-drive" , element: <SummarySelfDrive/> },
    { id:8, path: "/:location/summary-airport" , element: <SummaryAirport/> },
    { id:9, path: "/:location/summary-outstation" , element: <SummaryOutstation/> },
    { id:10, path: "/profile/:id" , element: <Profile/> },
    { id:11, path: "/contactus" , element: <ContactUs/> },
    { id:12, path: "/yachts" , element: <Yachts/> },
    { id:13, path: "/jets_copters" , element: <Jet/> },
    { id:14, path: "/payments" , element: <Payments/> },
    { id:15, path: "/terms-and-conditions" , element: <TermsAndConditions/> },
    { id:16, path: "/privacy-policy" , element: <PrivacyPolicy/> },
    { id:17, path: "/refund-and-cancellations" , element: <RefundAndCancellation/> },
    { id:18, path: "/feedback" , element: <Feedback/> },
    { id:19, path: "/partnerships" , element: <Partnerships/> },
    { id:20, path: "/partner_with_us" , element: <Partnerwithus/> },
    { id:21, path: "/admin" , element: <Adminpage/> },
    { id:22, path: "/about_us" , element: <Aboutus/> },
    { id:23, path: "/Explore_the_Ultimate_Luxury_Car_Rental_Experience_in_Bangalore_blog" , element: <Car_blog/> },
    { id:24, path: "/Car_Rental_Bangalore_Find_the_Perfect_Luxury_Vehicl_for_Your_Needs_blog" , element: <Car_blogg/> },
    { id:25, path: "/Take_your_dreams_back_on_the_road_with_Go_Blck" , element: <Car_blog3/> },
    { id:26, path: "/Rev_Up_Your_Travels_How_Blck_Luxury_Makes_Every_Mile_Count" , element: <Car_blog4/> },
    { id:27, path: "/Beyond_the_Ordinary_Unveiling_the_Glamour_of_Luxury_Car" , element: <Car_blog5/> },
    { id:28, path: "/Gear_Up_for_Adventure_Exploring_Blck_Luxury" , element: <Car_blog6/> },
    { id:28, path: "/Cruise_into_Comfort_Where_Every_Drive_Tells_a_Story" , element: <Car_blog7/> },

    { id:29, path: "/Main_blog" , element: <Main_blog/> },
    { id:30, path: "/Experience_the_Pinnacle_of_Elegance_with_Blck_Luxury_Car_Rentals_in_Dubai" , element: <Car_blog8/> },
    { id:31, path: "/Class_and_Comfort_Luxury_Car_Rentals_Redefined_for_You" , element: <Car_blog9/> },
    { id:32, path: "/Celebrate_New_Year_2025_with_Lavish_Road_Trips_Across_India" , element: <Car_blog10/> },

    { id:33, path: "/Thank_You" , element: <Thankyou/> },



    // { id:21, path: "/navbar" , element: <Navbar/> },
]